import dayjs from 'dayjs';
import _ from 'lodash';
import { accesses, grants, isGrantAccessed } from 'utils/grants';

/* eslint-disable complexity */
export function convertFieldsValuesToDbEntity(
    orderFields,
    allServices,
    allDetails,
    status = 'not_complete',
    user,
    isRetailOrder,
    { ignoreFieldsOnAdd } = {}
) {
    const services = _(orderFields.services)
        .filter(Boolean)
        .filter(service => _.get(service, 'serviceName'))
        .map(service => {
            const {
                serviceName: name,
                servicePrice: price,
                serviceCount: count,
                employeeId,
                ownDetail,
                serviceHours: hours,
                laborId,
                agreement,
                comment,
                crewId
            } = service;

            const serviceConfig = allServices.find(({ id, type }) => `${type}|${id}` === name);

            const baseService = { price, count, hours, employeeId, ownDetail };
            const serviceType = !serviceConfig
                ? { type: 'custom', serviceName: name }
                : { type: name.split('|')[0], serviceId: name.split('|')[1] };

            const result = {};

            if (name) {
                result.serviceName = name;
            }
            if (laborId) {
                result.serviceId = laborId;
            }
            if (price) {
                result.servicePrice = price;
            }
            if (employeeId) {
                result.employeeId = employeeId;
            }
            if (crewId) {
                result.crewId = crewId;
            }
            if (hours) {
                result.serviceHours = hours;
            }
            if (agreement) {
                result.agreement = agreement;
            }
            if (comment) {
                result.comment = { comment };
            }

            return result;
        })
        .value();
    const details = _(orderFields.details)
        .filter(Boolean)
        .filter(detail => _.get(detail, 'detailName') || _.get(detail, 'productId'))
        .map(detail => {
            const {
                detailName: detailId,
                detailPrice: price,
                detailCount: count,
                detailCode: code,
                detailBrandName: brandId,
                purchasePrice,
                storage,
                productId,
                productCode,
                storeGroupId,
                agreement
                // using: using,
            } = detail;
            const detailConfig = allDetails.details.find(({ detailId: id }) => String(id) === detailId);
            let detailCustom = { detailId };

            if (storage) {
                detailCustom = { productId };
            } else if (!detailConfig) {
                detailCustom = { name: detailId };
            }

            const brandConfig = allDetails.brands.find(({ brandId: id }) => String(id) === brandId);

            const baseDetail = {
                storeGroupId,
                code,
                productCode,
                price,
                count,
                purchasePrice
            };

            let brandCustom = {};
            if (!brandConfig) {
                if (brandId) {
                    brandCustom = { brandName: brandId };
                }
            } else {
                brandCustom = { brandId };
            }

            return {
                storeGroupId: storeGroupId || null,
                agreement,
                price,
                count,
                purchasePrice
                /* Marian details table fix / save button fix
                ...baseDetail,
                ...detailCustom,
                ...brandCustom,
                // ...storage && using ? { using } : {}, */
            };
        })
        .value();
    const beginDate = _.get(orderFields, 'stationLoads[0].beginDate');
    const beginTime = _.get(orderFields, 'stationLoads[0].beginTime');

    const deliveryDate = _.get(orderFields, 'deliveryDate');
    const deliveryTime = _.get(orderFields, 'deliveryTime');
    const deliveryDateTimeActual = _.get(orderFields, 'deliveryDatetime');

    // TODO: refactor for utils concatDateTime
    const dayPart = beginDate && dayjs(beginDate).format('YYYY-MM-DD');

    const hourPart = beginTime && dayjs(beginTime).utc().format('HH:mm');

    const deliveryDayPart = deliveryDate && dayjs(deliveryDate).format('YYYY-MM-DD');

    const deliveryHourPart = deliveryTime && dayjs(deliveryTime).utc().format('HH:mm');

    const beginDatetime = dayPart && hourPart && dayjs(`${dayPart}T${hourPart}:00.000Z`).toISOString();

    const retailDeliveryDatetime = dayjs(deliveryDateTimeActual).toISOString();
    const deliveryDatetime =
        deliveryDayPart && deliveryHourPart
            ? dayjs(`${deliveryDayPart}T${deliveryHourPart}:00.000Z`).toISOString() // eslint-disable-next-line no-extra-parens
            : status === 'success'
            ? dayjs(new Date()).toISOString()
            : retailDeliveryDatetime;

    const orderDuration = _.get(orderFields, 'stationLoads[0].duration');
    const stationLoadsEntity = _.get(orderFields, 'stationLoads', [])
        .filter(item => item)
        .filter(({ beginDate, beginTime }) => ![beginDate, beginTime].some(_.isNil))
        .map(obj => {
            const utcOffset = dayjs().utcOffset() / 60;
            const hour = obj.beginTime && dayjs(obj.beginTime).hour();
            const hourUtc = obj.beginTime && dayjs(obj.beginTime).utc().hour();

            const dayPart = obj.beginDate && dayjs(obj.beginDate);
            const hourPart = obj.beginTime && dayjs(obj.beginTime).utc();

            if(utcOffset > 0 && hourUtc > hour) {
                dayPart.subtract(1, 'day');
            } else if(utcOffset < 0 && hour > hourUtc) {
                dayPart.add(1, 'day');
            }

            const beginDatetime = dayPart && hourPart && dayjs(`${dayPart.format('YYYY-MM-DD')}T${hourPart.format('HH:mm')}:00.000Z`).toISOString();

            return {
                ..._.omit(obj, ['beginDate', 'beginTime', 'station']),
                stationNum: obj.station,
                beginDatetime
            };
        });

    const stationLoads = _.each(stationLoadsEntity);
    // TODO: rewrite this method
    // prepare order to request format
    const order = {
        clientId: _.get(orderFields, 'selectedClient.clientId'),
        clientVehicleId: _.get(orderFields, 'clientVehicle') || null,
        businessRequisiteId: _.get(orderFields, 'requisite') || null,
        managerId: _.get(orderFields, 'manager'),
        duration: orderDuration ? Math.max(orderDuration, 0.5) : orderDuration,
        clientPhone: _.get(orderFields, 'clientPhone'),
        paymentMethod: _.get(orderFields, 'paymentMethod') || null,
        clientRequisiteId: _.get(orderFields, 'clientRequisite') || null,
        status,
        beginDatetime,
        deliveryDatetime,
        services,
        details,
        stationLoads,
        appurtenanciesResponsibleId: _.get(orderFields, 'appurtenanciesResponsible') || null,
        employeeId: _.get(orderFields, 'employee') || null,
        stationNum: _.get(orderFields, 'stationLoads[0].station') || null,
        detailsDiscount: _.get(orderFields, 'detailsDiscount'),
        servicesDiscount: _.get(orderFields, 'servicesDiscount'),
        odometerValue: _.get(orderFields, 'odometerValue'),
        discount: _.get(orderFields, 'discount'),
        recommendation: _.get(orderFields, 'recommendation'),
        vehicleCondition: _.get(orderFields, 'vehicleCondition'),
        businessComment: _.get(orderFields, 'businessComment'),
        comment: _.get(orderFields, 'comment'),
        systemComment: _.get(orderFields, 'systemComment'),
        businessLocationId: _.get(orderFields, 'businessLocationId') || null,
        insertMode: true,
        // updateRepairMap: true,
        orderBloke: _.get(orderFields, 'orderBloke', 'VEHICLE'),
        clientAggregateId: _.get(orderFields, 'clientAggregateId'),
        subStatus: _.get(orderFields, 'subStatus'),
        recalculateDuration: _.get(orderFields, 'recalculateDuration')
    };

    const addServices = _.get(orderFields, 'addServices');
    if (addServices) {
        order.addServices = addServices;
    }

    const orderClearedFields = _.mapValues(order, value => (value === '' ? null : value));
    // omit forbidden fields
    const rolesOmitFieldsFunctions = [
        orderEntity =>
            !isGrantAccessed(
                user,
                grants.OPERATIONS_ORDER_DOCUMENT_ORDER_HEADER_CLOSED_ORDER_EDITING_MILEAGE,
                accesses.ROWO
            )
                ? _.omit(orderEntity, ['odometerValue'])
                : orderEntity,
        orderEntity =>
            // !isGrantAccessed(user, grants.OPERATIONS_ORDER_DOCUMENT_ORDER_HEADER_RESPONSIBLE, accesses.ROWO) &&
            // !ignoreFieldsOnAdd ? _.omit(orderEntity, ['managerId']) :
            orderEntity,
        // orderEntity =>
        //     !isGrantAccessed(user, grants.OPERATIONS_ORDER_DOCUMENT_ORDER_HEADER_CAR_INFO, accesses.ROWO)
        //         ? _.omit(orderEntity, ['clientVehicleId'])
        //         : orderEntity,
        orderEntity =>
            !isGrantAccessed(user, grants.OPERATIONS_ORDER_DOCUMENT_ORDER_HEADER_LOCATION, accesses.ROWO)
                ? _.omit(orderEntity, ['businessLocationId'])
                : orderEntity,
        orderEntity =>
            !isGrantAccessed(user, grants.OPERATIONS_ORDER_DOCUMENT_ORDER_HEADER_REPAIR_TYPE, accesses.ROWO) &&
            !ignoreFieldsOnAdd
                ? _.omit(orderEntity, ['orderBloke'])
                : orderEntity,
        orderEntity =>
            !isGrantAccessed(user, grants.OPERATIONS_ORDER_DOCUMENT_COMMENTS, accesses.ROWO)
                ? _.omit(orderEntity, ['recommendation', 'vehicleCondition', 'businessComment', 'comment'])
                : orderEntity,
        orderEntity =>
            !isGrantAccessed(user, grants.OPERATIONS_ORDER_DOCUMENT_POSTS, accesses.ROWO)
                ? _.omit(orderEntity, ['stationLoads'])
                : orderEntity,
        orderEntity =>
            !isGrantAccessed(user, grants.OPERATIONS_ORDER_DOCUMENT_REQUEST, accesses.ROWO)
                ? _.omit(orderEntity, ['laborRequests'])
                : orderEntity,
        orderEntity =>
            !isGrantAccessed(user, grants.OPERATIONS_ORDER_DOCUMENT_JOBS, accesses.ROWO)
                ? _.omit(orderEntity, ['services'])
                : orderEntity,
        orderEntity =>
            !isGrantAccessed(user, grants.OPERATIONS_ORDER_DOCUMENT_PARTS, accesses.ROWO)
                ? _.omit(orderEntity, ['details'])
                : orderEntity
    ];

    return rolesOmitFieldsFunctions.reduce((prev, current) => current(prev), orderClearedFields);
}

// required fields list ( Form rules helper)
export const requiredFieldsOnStatuses = values => {
    /* eslint-disable camelcase */
    let statuses = {
        invite: ['clientVehicle', 'manager', 'clientPhone', 'services', 'details'],
        call: ['clientPhone', 'manager', 'services', 'details'],
        not_complete: [
            'manager',
            'services',
            'details',
            'station',
            'stationLoads[0].beginDate',
            'stationLoads[0].beginTime'
        ],
        required: ['manager', 'services', 'details', 'stationLoads[0].beginDate', 'stationLoads[0].beginTime'],
        reserve: [
            'stationLoads[0].beginDate',
            'stationLoads[0].beginTime',
            'manager',
            'station',
            'services',
            'details'
        ],
        approve: [
            'stationLoads[0].beginDate',
            'stationLoads[0].beginTime',
            'manager',
            'clientPhone',
            'station',
            'services',
            'details'
        ],
        redundant: ['services', 'details'],
        cancel: ['services', 'details'],
        progress: [
            'stationLoads[0].beginDate',
            'stationLoads[0].beginTime',
            'manager',
            'clientPhone',
            'clientVehicle',
            'station',
            'deliveryDate',
            'deliveryTime',
            'services',
            'details'
        ],
        success: [
            'stationLoads[0].beginDate',
            'stationLoads[0].beginTime',
            'manager',
            'clientPhone',
            'clientVehicle',
            'station',
            'services',
            'details'
        ]
    };

    if (values['stationLoads[0].beginTime'] || values['stationLoads[0].beginDate']) {
        statuses = _.mapValues(statuses, fields =>
            _.uniq([...fields, 'stationLoads[0].beginTime', 'stationLoads[0].beginDate'])
        );
    }

    if (values.deliveryDate || values.deliveryTime) {
        statuses = _.mapValues(statuses, fields => _.uniq([...fields, 'deliveryDate', 'deliveryTime']));
    }

    return statuses;
};
