import { Table } from 'antd';
import dayjs from 'dayjs';
import React, { useMemo } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

const LogsMainTable = ({ logs, count, page, setPage, intl: { formatMessage } }) => {
    const logsColumns = useMemo(
        () => [
            {
                title: formatMessage({ id: 'date' }),
                dataIndex: 'auditDate',
                render: date => dayjs(date).format('DD.MM.YYYY HH:mm')
            },
            {
                title: formatMessage({ id: 'locations.action' }),
                dataIndex: 'type',
                render: type => formatMessage({ id: `logs-table.v2.${type}` })
            },
            {
                title: formatMessage({ id: 'responsible' }),
                dataIndex: 'responsibleName'
            },
            {
                title: formatMessage({ id: 'storage.type' }),
                dataIndex: 'dataKey',
                render: type => formatMessage({ id: `types.${type}` })
            }
        ],
        [formatMessage]
    );

    const expandedRowRender = ({ stateBefore, stateAfter, type, dataKey }, index) => {
        let dataSource = [];
        let columns = [];
        if (type === 'UPDATE' && dataKey !== 'PRODUCTS') {
            // has both stateBefore and stateAfter
            const keys = Object.keys(stateAfter);

            const states = [
                {
                    title: formatMessage({ id: 'after_change' }),
                    dataIndex: 'after',
                    onCell: () => ({ style: { color: 'var(--green)' } })
                }
            ];
            stateBefore &&
                states.unshift({
                    title: formatMessage({ id: 'before_change' }),
                    dataIndex: 'before',
                    onCell: () => ({ style: { color: 'var(--disabled)' } })
                });

            columns = [
                {
                    title: formatMessage({ id: 'field' }),
                    dataIndex: 'field',
                    render: key => <FormattedMessage id={`logs.${key}`} />
                },
                ...states
            ];

            dataSource = keys.map(key => ({
                field: key,
                after: stateAfter[key],
                id: key,
                ...(stateBefore && { before: stateBefore[key] })
            }));
        } else {
            if (dataKey === 'PRODUCTS') {
                columns = [
                    {
                        title: formatMessage({ id: 'storage.product_code' }),
                        dataIndex: 'code'
                    },
                    {
                        title: formatMessage({ id: 'brand' }),
                        dataIndex: 'brand'
                    },
                    {
                        title: formatMessage({ id: 'name_2' }),
                        key: 'name',
                        render: ({name, productName}) => name || productName
                    },
                    {
                        title: formatMessage({ id: 'storage.purchase_price' }),
                        dataIndex: 'stockPrice'
                    },
                    {
                        title: formatMessage({ id: 'price' }),
                        dataIndex: 'sellingPrice'
                    },
                    {
                        title: formatMessage({ id: 'order_docs.quantity' }),
                        dataIndex: 'quantity'
                    }
                ];
            } else if (dataKey === 'SERVICES') {
                columns = [
                    {
                        title: formatMessage({ id: 'directories.orders' }),
                        dataIndex: 'orderId'
                    },
                    {
                        title: formatMessage({ id: 'name_2' }),
                        dataIndex: 'name'
                    },
                    {
                        title: formatMessage({ id: 'order_docs.quantity' }),
                        dataIndex: 'count'
                    },
                    {
                        title: formatMessage({ id: 'price' }),
                        dataIndex: 'price'
                    },
                    {
                        title: formatMessage({ id: 'sum' }),
                        dataIndex: 'sum'
                    }
                ];
            }
            dataSource = stateAfter;
        }

        return (
            <Table
                bordered
                columns={columns}
                dataSource={dataSource}
                rowKey={({ id, code }) => `${id || code}_${index}`}
                size='small'
            />
        );
    };

    return (
        <Table
            bordered
            columns={logsColumns}
            dataSource={logs}
            expandable={{
                expandedRowRender,
                defaultExpandedRowKeys: ['0']
            }}
            pagination={{
                size: 'small',
                total: count,
                hideOnSinglePage: true,
                current: page,
                onChange: page => {
                    setPage(page);
                }
            }}
            rowKey='id'
            size='small'
        />
    );
};

export default injectIntl(LogsMainTable);
