import { InfoCircleOutlined } from '@ant-design/icons';
import { Button, Form, Input, Select } from 'antd';
import { onChangeProfileForm, submitProfileForm } from 'core/forms/profileForm/duck';
import _ from 'lodash';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { getBusinessType } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import Styles from './styles.m.css';

const { Option } = Select;

const formItemLayout = {
    labelCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 },
        lg: { span: 8 },
        xl: { span: 8 },
        xxl: { span: 8 }
    },
    wrapperCol: {
        xs: { span: 24 },
        sm: { span: 24 },
        md: { span: 24 },
        lg: { span: 8 },
        xl: { span: 8 },
        xxl: { span: 8 }
    },
    colon: false
};

const mapDispatchToProps = {
    onChangeProfileForm,
    submitProfileForm
};

const mapStateToProps = state => {
    return {
        user: state.auth,
        profileUpdating: state.ui.profileUpdating
    };
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
export class ProfileForm extends Component {
    componentDidMount() {
        this.fetchAdditionalData();
    }

    submitProfile = values => {
        const { submitProfileForm, onChangeProfileForm } = this.props;
        submitProfileForm(values);

        // onChangeProfileForm();
    };

    fetchAdditionalData = async () => {
        // await fetchAPI('GET', 'businesses');
    };

    render() {
        const {
            user,
            intl: { formatMessage }
        } = this.props;

        const businessType = getBusinessType();

        return (
            <Form
                className={Styles.profileForm}
                initialValues={{
                    name: user.name,
                    surname: user.surname,
                    language: user.language,
                    businessTypes: businessType || 'DEFAULT',
                    packageStatuses: _.get(user, 'packageListDetailStatuses[0]'),
                    incomeOnlyWithCell: user.incomeOnlyWithCell
                }}
                labelCol={formItemLayout.labelCol}
                name='profileForm'
                onFinish={this.submitProfile}
                wrapperCol={formItemLayout.wrapperCol}
            >
                <Form.Item
                    label={<FormattedMessage id='name' />}
                    name='name'
                    rules={[
                        {
                            required: true,
                            message: formatMessage({
                                id: 'profile-form.please_enter_your_name'
                            })
                        }
                    ]}
                >
                    <Input
                        placeholder={formatMessage({
                            id: 'profile-form.name.placeholder'
                        })}
                    />
                </Form.Item>
                <Form.Item
                    label={<FormattedMessage id='surname' />}
                    name='surname'
                    rules={[
                        {
                            required: true,
                            message: 'profile-form.please_enter_your_surname'
                        }
                    ]}
                >
                    <Input
                        placeholder={formatMessage({
                            id: 'profile-form.surname.placeholder'
                        })}
                    />
                </Form.Item>
                <Form.Item
                    label={<FormattedMessage id='profile-form.language' />}
                    name='language'
                    rules={[
                        {
                            required: true,
                            message: formatMessage({
                                id: 'profile-form.please_select_your_language'
                            })
                        }
                    ]}
                    tooltip={{
                        title: formatMessage({
                            id: 'profile-form.relogin_required'
                        }),
                        icon: <InfoCircleOutlined />
                    }}
                >
                    <Select
                        getPopupContainer={trigger => trigger.parentNode}
                        placeholder={formatMessage({
                            id: 'profile-form.language.placeholder'
                        })}
                    >
                        <Option value='en'>English</Option>
                        {/* ua should be uk (BE legacy) */}
                        <Option value='ua'>Ukrainian</Option>
                        {/* <Option value='ru'>Русский</Option> */}
                        <Option value='bg'>Bulgarian</Option>
                        <Option value='cz'>Czech</Option>
                    </Select>
                </Form.Item>
                {isGrantAccessed(user, grants.DIRECTORIES_USER_SETTINGS_GENERAL_BUSINESS_TYPE, accesses.ROWO) && (
                    <Form.Item
                        label={<FormattedMessage id='profile-form.business_types' />}
                        name='businessTypes'
                        tooltip={{
                            title: formatMessage({
                                id: 'profile-form.relogin_required'
                            }),
                            icon: <InfoCircleOutlined />
                        }}
                    >
                        <Select
                            getPopupContainer={trigger => trigger.parentNode}
                            placeholder={formatMessage({
                                id: 'profile-form.business_types'
                            })}
                        >
                            <Option value='DEFAULT'><FormattedMessage id='general_settings.car_service_type'/></Option>
                            <Option value='TIRE_STATION'><FormattedMessage id='general_settings.tire_fitting_type' /></Option>
                        </Select>
                    </Form.Item>
                )}

                <Form.Item className={Styles.itemBtn}>
                    <Button className={Styles.saveBtn} htmlType='submit' type='primary'>
                        <FormattedMessage id='save' />
                    </Button>
                </Form.Item>
            </Form>
        );
    }
}
