import { Table } from 'antd';
import { Catcher } from 'commons';
import { changeModalStatus, initOrderTasksForm } from 'core/forms/orderTaskForm/duck';
import { MODALS, setModal } from 'core/modals/duck';
import { get } from 'lodash';
import { EmployeeSubTasksModal, EmployeeTaskModal } from 'modals';
import { counterpartValues, documentTypeValues } from 'modals/EmployeeTaskModal/constants';
import React, { useEffect, useState } from 'react';
import { injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import book from 'routes/book';
import { fetchAPI } from 'utils';
import { columnsConfig } from './config';
import Styles from './styles.m.css';

const TasksTab = ({
    storeDocId,
    activeKey,
    counterpartType,
    counterparts,
    setModal,
    user,
    counterpartBusinessSupplierName,
    counterpartBusinessSupplierId,
    intl: { formatMessage }
}) => {
    const [tasks, setTasks] = useState([]);

    const fetchTasks = async () => {
        const { employeeTasks, stats } = await fetchAPI(
            'GET',
            '/employee_tasks',
            {
                filters: {
                    page: 1,
                    storeDocId,
                    statuses: ['NEW', 'INWORK', 'DELEGATED', 'COMPLETED']
                }
            },
            undefined,
            {
                handleErrorInternally: true
            }
        );

        setTasks(employeeTasks);
    };

    useEffect(() => {
        if (activeKey === 'tasks') fetchTasks();
    }, [storeDocId, activeKey]);

    const onEmployeeTask = () => {
        setModal(MODALS.EMPLOYEE_TASK_MODAL, {
            initialValues: {
                storeDocId,
                documentType: documentTypeValues.STORE_DOC,
                counterpartyType:
                    counterpartType === 'supplier'
                        ? counterpartValues.BUSINESS_SUPPLIER
                        : counterpartType.toUpperCase(),
                ...counterparts
            },
            afterCreateCallback: () => {
                fetchTasks();
            }
        });
    };

    const onEmployeeSubTasks = id => {
        setModal(MODALS.EMPLOYEE_SUBTASKS_MODAL, {
            taskId: id
        });
    };

    const handleTaskRedirect = async task => {
        // if in NEW status then should be change to INWORK and task is assigned to manager

        if (task.status === 'NEW' && task.performerIds.includes(user.id)) {
            await fetchAPI('PUT', `employee_tasks/${task.id}`, undefined, {
                status: 'INWORK'
            }).then(() => {
                history.push({
                    pathname: `${book.myTasksPage}/${task.id}`
                });
            });
        } else {
            history.push({
                pathname: `${book.myTasksPage}/${task.id}`
            });
        }
    };

    const columns = columnsConfig({
        onEmployeeTask,
        onEmployeeSubTasks,
        handleTaskRedirect
    });

    return (
        <Catcher>
            <div className={Styles.tableCont}>
                <Table
                    bordered
                    columns={columns}
                    dataSource={tasks}
                    pagination={false}
                    rowClassName={item => {
                        if (get(item, 'status') === 'NEW') return Styles.newTaskRow;
                        if (get(item, 'status') === 'COMPLETED') return Styles.completedTaskRow;
                    }}
                    rowKey='id'
                    scroll={{ x: 'auto', y: '60vh' }}
                    size='small'
                />
            </div>

            <EmployeeSubTasksModal />
            <EmployeeTaskModal
                counterpartBusinessSupplierId={counterpartBusinessSupplierId}
                counterpartBusinessSupplierName={counterpartBusinessSupplierName}
            />
        </Catcher>
    );
};
const mapStateToProps = state => ({
    // modal: state.modals.modal,
    user: state.auth
});
const mapDispatchToProps = {
    setModal,
    initOrderTasksForm,
    changeModalStatus
};
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(TasksTab));
