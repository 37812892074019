/* eslint-disable prefer-destructuring */
/* eslint-disable react/sort-comp */
/* eslint-disable max-classes-per-file */
import {
    CheckOutlined,
    FileImageOutlined,
    FilterFilled,
    FilterOutlined,
    FolderOpenOutlined,
    SearchOutlined,
    VideoCameraAddOutlined
} from '@ant-design/icons';
import {
    Button,
    Checkbox,
    Dropdown,
    Input,
    Menu,
    Modal,
    Radio,
    Select,
    Space,
    Table,
    Tooltip,
    notification
} from 'antd';
import { AvailabilityIndicator } from 'components';
import { MODALS, loadModal, saveModal, setModal } from 'core/modals/duck';
import _ from 'lodash';
import {
    DetailSupplierModal,
    DetailWarehousesCountModal,
    PreviewMediaFilesModal,
    SearchDetailModal,
    StoreProductTrackingModal
} from 'modals';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import book from 'routes/book';
import { BookIcon, DeliveryTruckIcon, StockIcon, VinIcon } from 'theme';
import { fetchAPI, fetchTecdocAPI } from 'utils';
import { accesses, grants, isGrantAccessed } from 'utils/grants';
import {
    BrandSelectModal,
    PhotoModal,
    SourceSelectModal,
    StorageSelectModal,
    StoreGroupSelectModal,
    SupplierSelectModal
} from './modals';
import './styles.less';

const { Option } = Select;

const MODIFICATION = 'MODIFICATION';
const STORAGE = 'STORAGE';
const OILS = 'OILS';
const CODES = 'CODES';
const CROSSES = 'CROSSES';

export const formatDetailArray = products => {
    if (!_.isArray(products)) {
        return [];
    }

    return products.map(item => {
        const elem = { ...item };
        if (elem.businessSupplierId !== 0 || elem.brandId === 0) {
            delete elem.productId;
        }
        elem.attributes = elem.attributes || [];

        if (elem.defaultWarehouseName) {
            elem.productId = elem.id;
        }

        if (elem.price) {
            if (elem.price.businessSupplierId === 0) {
                elem.productId = elem.price.id;
            }
            elem.store = elem.price.store;
            elem.purchasePrice = elem.price.purchasePrice;
            elem.businessSupplierId = elem.price.businessSupplierId;
            elem.businessSupplierName = elem.price.businessSupplierName;
            elem.salePrice =
                elem.sellingPrice || elem.price.purchasePrice * (elem.price.markup ? elem.price.markup : 1.4);
            elem.supplierOriginalCode = elem.price.supplierOriginalCode;
            elem.supplierPartNumber = elem.price.supplierPartNumber;
        }

        return elem;
    });
};

const mapStateToProps = state => ({
    user: state.auth,
    modalProps: state.modals.modalProps,
    isMobile: state.ui.views.isMobile,
    suppliers: state.suppliers.suppliers,
    warehouses: state.warehouses.warehouses
});

const mapDispatchToProps = {
    setModal,
    saveModal,
    loadModal
};

@injectIntl
@connect(mapStateToProps, mapDispatchToProps)
class DetailCatalogueModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSource: [],
            page: 1,
            pageSize: 15,
            total: 0,
            visibleBrandSelectModal: false,
            comparation: 'EQUAL',
            mode: 0,
            isAvailable: true,
            lastIncome: false,
            listBrands: [],
            selectedRowKeys: [],
            selectedRows: [],
            loading: false,
            originalCode: undefined,
            selectedSearchMode: STORAGE,
            mediaFilesRow: undefined
        };

        this.input = React.createRef();

        this.handleStorageSearch = _.debounce(() => {
            this.fetchStorage();
        }, 500);

        this.columns = () => [
            {
                key: 'actions',
                render: row => {
                    const code = row.code || row.partNumber;

                    return (
                        !row.emptyRow && (
                            <div className='actions'>
                                <Tooltip title={<FormattedMessage id='settings_page.media' />} zIndex={2001}>
                                    <Button
                                        icon={<VideoCameraAddOutlined />}
                                        onClick={() => {
                                            this.setState({
                                                mediaFilesRow: row
                                            });
                                        }}
                                    />
                                </Tooltip>
                                <Tooltip
                                    title={<FormattedMessage id='order_form_table.open_photo_tooltip' />}
                                    zIndex={2001}
                                >
                                    <Button
                                        data-qa='button_open_photo_modal_order_detail_modal'
                                        // disabled={!get(row, 'hasAttributes') && !get(row, 'hasImages')}
                                        icon={<FileImageOutlined />}
                                        onClick={() => {
                                            this.setState({
                                                photoModalRow: row
                                            });
                                        }}
                                    />
                                </Tooltip>
                                <Tooltip
                                    title={<FormattedMessage id='order_form_table.add_to_order_tooltip' />}
                                    zIndex={2001}
                                >
                                    <Button
                                        data-qa='button_handle_ok_select_order_detail_modal'
                                        disabled={this.state.loading}
                                        icon={<CheckOutlined />}
                                        onClick={() => {
                                            this.setState({ loading: true });
                                            this.handleOk(row);
                                        }}
                                    />
                                </Tooltip>
                                <Tooltip title={<FormattedMessage id='order_form_table.apply_btn' />} zIndex={2001}>
                                    <Button
                                        data-qa='btn_fetch_storage_oils_order_detail_modal'
                                        disabled={!code}
                                        icon={<SearchOutlined />}
                                        onClick={async () => {
                                            await this.setState({
                                                code,
                                                brandId: row.brandId,
                                                page: 1
                                            });
                                            this.fetchFinalCrosses();
                                        }}
                                    />
                                </Tooltip>
                            </div>
                        )
                    );
                }
            },
            {
                title: <FormattedMessage id='order_form_table.detail_code' />,
                key: 'code',
                ...this.getColumnSearchProps('filterCode'),
                width: '15%',
                render: row => {
                    const code = row.code || row.partNumber;
                    if (row.emptyRow) {
                        return (
                            <FormattedMessage
                                id={
                                    this.state.mode === 0
                                        ? 'order_form_table.directory_spare_parts'
                                        : this.state.mode === 2
                                        ? 'order_form_table.choose_the_desired_product'
                                        : 'order_form_table.replacements_cross_references'
                                }
                            />
                        );
                    }

                    const name = row.name && row.name.length > 55 ? `${row.name.slice(0, 55)}...` : row.name;
                    const description = row.description > 55 ? `${row.description.slice(0, 55)}...` : row.description;

                    return (
                        <div>
                            <div
                                style={{
                                    fontWeight: 700,
                                    textDecoration: code && 'underline',
                                    cursor: 'pointer'
                                }}
                            >
                                {(row.businessSupplierId === 0 || this.state.mode === 0) && row.productId ? (
                                    <Link target='_blank' to={`${book.product}/${row.productId}`}>
                                        {code}
                                    </Link>
                                ) : (
                                    <span
                                        onClick={() => {
                                            if (
                                                isGrantAccessed(
                                                    this.props.user,
                                                    grants.DIRECTORIES_PRODUCTS_LIST_GENERAL_INFORMATION_CARD
                                                )
                                            ) {
                                                this.props.setModal(MODALS.STORE_PRODUCT, {
                                                    code,
                                                    brandId: row.brandId,
                                                    zIndex: 2002,
                                                    brandName: row.brandName,
                                                    name: row.name,
                                                    groupId: row.storeGroupId,
                                                    productUnitId: row.partUnitId || 1,
                                                    onSubmit: async (id, code) => {
                                                        notification.success({
                                                            message: this.props.intl.formatMessage({
                                                                id: 'barcode.success'
                                                            })
                                                        });
                                                        await this.setState({
                                                            code,
                                                            brandId: row.brandId,
                                                            page: 1
                                                        });
                                                        this.fetchFinalCrosses();
                                                    }
                                                });
                                            }
                                        }}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        {code || <FormattedMessage id='long_dash' />}
                                    </span>
                                )}
                            </div>
                            <Tooltip title={row.name || row.description}>
                                <div style={{ fontSize: 12 }}>{name || description}</div>
                            </Tooltip>
                        </div>
                    );
                }
            },
            {
                title: <FormattedMessage id='order_form_table.brand' />,
                key: 'brand',
                ...this.getColumnSearchProps('filterBrand'),
                render: row => {
                    const code = row.code || row.partNumber;

                    return (
                        <div>
                            <Tooltip
                                title={<FormattedMessage id='order_form_table.find_in_catalog_tooltip' />}
                                zIndex={2001}
                            >
                                <div
                                    onClick={async () => {
                                        await this.setState({
                                            code,
                                            brandId: row.brandId || null,
                                            page: 1
                                        });
                                        this.fetchFinalCrosses();
                                    }}
                                    style={{
                                        fontWeight: 700,
                                        textDecoration: code && 'underline',
                                        cursor: 'pointer'
                                    }}
                                >
                                    {row.brandName}
                                </div>
                            </Tooltip>
                        </div>
                    );
                }
            },
            // {
            //     title: <FormattedMessage id='order_form_table.info' />,
            //     key: 'attributes',
            //     render: row => {
            //         if (row.emptyRow) return <React.Fragment></React.Fragment>;

            //         let title = '';
            //         let data = '';
            //         const attributes = _.get(row, 'attributes') || [];
            //         attributes.map(({ description, value }) => {
            //             title += `${description}: ${value}\n`;
            //             data += `${value}, `;
            //         });

            //         const { comment } = row;
            //         const parametersShort = [
            //             row.length && row.length !== null ? row.length : null,
            //             row.width && row.width !== null ? row.width : null,
            //             row.height && row.height !== null ? row.height : null,
            //             row.weight && row.weight !== null ? row.weight : null,
            //             row.externalDiameter && row.externalDiameter !== null ? row.externalDiameter : null,
            //             row.internalDiameter && row.internalDiameter !== null ? row.internalDiameter : null
            //         ];

            //         const positionsShort = [
            //             row.front && 'пер',
            //             row.top && 'вер',
            //             row.external && 'зовн',
            //             row.rear && 'зад',
            //             row.bottom && 'ниж',
            //             row.internal && 'внут',
            //             row.left && 'лів',
            //             row.central && 'цен',
            //             row.right && 'прав'
            //         ];

            //         const positions = [
            //             row.front && 'front',
            //             row.top && 'top',
            //             row.external && 'external',
            //             row.rear && 'rear',
            //             row.bottom && 'bottom',
            //             row.internal && 'internal',
            //             row.left && 'left',
            //             row.central && 'central',
            //             row.right && 'right'
            //         ];

            //         const parameters = [
            //             row.length && row.length !== null ? (
            //                 <span>
            //                     <FormattedMessage id='detail_order.length' />
            //                     {':'} {row.length}
            //                     {'; '}
            //                 </span>
            //             ) : null,
            //             row.width && row.width !== null ? (
            //                 <span>
            //                     <FormattedMessage id='detail_order.width' />
            //                     {':'} {row.width}
            //                     {'; '}
            //                 </span>
            //             ) : null,
            //             row.height && row.height !== null ? (
            //                 <span>
            //                     <FormattedMessage id='detail_order.height' />
            //                     {':'} {row.height}
            //                     {'; '}
            //                 </span>
            //             ) : null,
            //             row.weight && row.weight !== null ? (
            //                 <span>
            //                     <FormattedMessage id='detail_order.weight' />
            //                     {':'} {row.weight}
            //                     {'; '}
            //                 </span>
            //             ) : null,
            //             row.externalDiameter && row.externalDiameter !== null ? (
            //                 <span>
            //                     <FormattedMessage id='detail_order.external_diameter' />
            //                     {':'} {row.externalDiameter}
            //                     {'; '}
            //                 </span>
            //             ) : null,
            //             row.internalDiameter && row.internalDiameter !== null ? (
            //                 <span>
            //                     <FormattedMessage id='detail_order.internal_diameter' />
            //                     {':'} {row.internalDiameter}
            //                     {'; '}
            //                 </span>
            //             ) : null
            //         ];

            //         const parametersFiltered = parameters.filter(elem => elem !== null);

            //         console.log(parametersFiltered, 'parametersFiltered');

            //         const tooltip = (
            //             <div>
            //                 <div style={{ textTransform: 'capitalize' }}>
            //                     {comment &&
            //                         comment !== null &&
            //                         `${this.props.intl.formatMessage({
            //                             id: 'comment'
            //                         })}:`}{' '}
            //                     {comment}
            //                 </div>
            //                 <div style={{ textTransform: 'capitalize' }}>
            //                     {parametersFiltered && parametersFiltered.length
            //                         ? `${this.props.intl.formatMessage({
            //                               id: 'commentary_modal.parameters'
            //                           })}: `
            //                         : undefined}
            //                     {parametersFiltered && parameters.filter(elem => elem !== null)}
            //                 </div>
            //                 <div style={{ textTransform: 'capitalize' }}>
            //                     {positions
            //                         .filter(elem => elem)
            //                         .map(elem => (
            //                             <span>
            //                                 <FormattedMessage id={`detail_order.${elem}`} />
            //                                 {'; '}
            //                             </span>
            //                         ))}
            //                 </div>
            //             </div>
            //         );

            //         return (
            //             <Tooltip title={tooltip} zIndex={2001}>
            //                 <div style={{ textTransform: 'capitalize' }}>
            //                     {comment &&
            //                         comment !== null &&
            //                         `${this.props.intl.formatMessage({
            //                             id: 'comment'
            //                         })}:`}{' '}
            //                     {comment}
            //                 </div>
            //                 <div style={{ textTransform: 'capitalize' }}>
            //                     {parametersFiltered && parametersFiltered.length
            //                         ? `${this.props.intl.formatMessage({
            //                               id: 'commentary_modal.parameters'
            //                           })}: `
            //                         : undefined}
            //                     {parametersFiltered && parametersFiltered.length
            //                         ? parametersShort.filter(elem => elem !== null).join('; ')
            //                         : undefined}
            //                 </div>
            //                 <div style={{ textTransform: 'capitalize' }}>
            //                     {positionsShort.filter(elem => elem).join('; ')}
            //                 </div>
            //             </Tooltip>
            //         );
            //     }
            // },
            {
                title: <FormattedMessage id='order_form_table.store_supplier' />,
                key: 'businessSupplierName',
                align: 'center',
                render: (row, _, key) => {
                    if (row.emptyRow) return <React.Fragment></React.Fragment>;

                    return (
                        <div>
                            <Tooltip
                                title={<FormattedMessage id='order_form_table.availability_store' />}
                                zIndex={2001}
                            >
                                <span
                                    onClick={() => {
                                        if (
                                            (row.businessSupplierId === 0 &&
                                                isGrantAccessed(
                                                    this.props.user,
                                                    grants.OPERATIONS_ORDER_DOCUMENT_PARTS_STORES_AND_RESERVATIONS
                                                )) ||
                                            (this.state.mode === 0 &&
                                                isGrantAccessed(
                                                    this.props.user,
                                                    grants.OPERATIONS_ORDER_DOCUMENT_PARTS_STORES_AND_RESERVATIONS
                                                ))
                                        ) {
                                            this.setState({
                                                warehousesModalSelectedRow: row
                                            });
                                        }
                                    }}
                                    style={{ cursor: 'pointer' }}
                                >
                                    {row.supplierId ? (
                                        <FormattedMessage id='long_dash' />
                                    ) : (
                                        row.businessSupplierName || row.defaultWarehouseName
                                    )}
                                </span>
                            </Tooltip>
                            {' / '}
                            {isGrantAccessed(this.props.user, grants.OPERATIONS_ORDER_DOCUMENT_PARTS, accesses.ROWO) ? (
                                <DetailSupplierModal
                                    brandId={row.brandId}
                                    clientId={this.props.clientId}
                                    detailCode={row.code || row.partNumber}
                                    disabled={!row.storeGroupId || !row.code || !row.brandId}
                                    hideButton
                                    onSelect={item => {
                                        this.props.setSupplier(item);
                                        this.handleOk({
                                            ...row,
                                            businessSupplierId: item.businessSupplierId,
                                            store: item.store,
                                            price: item.price,
                                            purchasePrice: item.purchasePrice,
                                            name: item.name
                                        });
                                    }}
                                    storeGroupId={row.storeGroupId}
                                    tableKey={key}
                                    user={this.props.user}
                                >
                                    {row.supplierId ? row.businessSupplierName : <FormattedMessage id='long_dash' />}
                                </DetailSupplierModal>
                            ) : row.supplierId ? (
                                row.businessSupplierName
                            ) : (
                                <FormattedMessage id='long_dash' />
                            )}
                        </div>
                    );
                }
            },
            {
                title: <FormattedMessage id='navigation.availability' />,
                key: 'store',
                align: 'center',
                render: ({ id, store, available, reservedCount, emptyRow }) => {
                    if (emptyRow) {
                        return <p></p>;
                    }

                    return id ? (
                        `${Number(available + reservedCount).toFixed(2)} / ${Number(available).toFixed(2)}`
                    ) : (
                        <AvailabilityIndicator indexArray={store} />
                    );
                }
            },
            {
                title: (
                    <div>
                        <FormattedMessage id='services_table.units' />
                    </div>
                ),
                key: 'unit',
                align: 'right',
                render: (row, unit) => {
                    if (row.emptyRow) {
                        return <p></p>;
                    }

                    return <span style={{ whiteSpace: 'nowrap' }}>{row.unit || <FormattedMessage id='pc' />}</span>;
                }
            },
            {
                title: <FormattedMessage id='order_form_table.purchasePrice' />,
                key: 'purchasePrice',
                align: 'right',
                render: ({ purchasePrice, stockPrice, emptyRow }) => {
                    if (emptyRow) {
                        return <p></p>;
                    }

                    const price = purchasePrice || stockPrice;
                    const strVal = ((price * 10) / 10).toFixed(2);

                    return price ? (
                        <span style={{ whiteSpace: 'nowrap' }}>
                            {`${strVal}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                        </span>
                    ) : (
                        <FormattedMessage id='long_dash' />
                    );
                }
            },
            {
                title: (
                    <div>
                        <FormattedMessage id='order_form_table.price' />
                        <p
                            style={{
                                color: 'var(--text2)',
                                fontSize: 12,
                                fontWeight: 400
                            }}
                        >
                            <FormattedMessage id='without' /> <FormattedMessage id='VAT' />
                        </p>
                    </div>
                ),
                key: 'salePrice',
                align: 'right',
                render: ({ salePrice, sellingPrice, emptyRow }) => {
                    if (emptyRow) {
                        return <p></p>;
                    }

                    const price = salePrice || sellingPrice;
                    const strVal = ((price * 10) / 10).toFixed(2);

                    return price ? (
                        <span style={{ whiteSpace: 'nowrap' }}>
                            {`${strVal}`.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                        </span>
                    ) : (
                        <FormattedMessage id='long_dash' />
                    );
                }
            }
        ];
    }

    vinModalAction = async ({ oem, storeGroupId }) => {
        await this.setState({ code: oem, oeCode: oem, storeGroupId, brandId: undefined, originalCode: oem });

        this.fetchFinalCrosses();
    };

    handleOk = row => {
        const {
            storeGroupId,
            name,
            description,
            partNumber,
            code,
            brandId,
            defaultWarehouseId,
            businessSupplierId,
            businessSupplierName,
            supplierOriginalCode,
            supplierPartNumber,
            purchasePrice,
            stockPrice,
            salePrice,
            sellingPrice,
            reservedCount,
            ordersAppurtenancies,
            store,
            productId,
            cellAddress,
            warehouseId,
            groupId,
            available,
            productUnitId,
            source,
            tradeCode,
            cellAddresses
        } = row;

        if (this.state.code) {
            this.saveSearchHistory(row);
        }

        const { onSelect } = this.props;
        const { storeGroupId: stateStoreGroupId } = this.state;
        const calculatedAvg = Math.floor(reservedCount / _.get(ordersAppurtenancies, 'length', 1));
        const supplierId = businessSupplierId || (defaultWarehouseId ? 0 : businessSupplierId);
        const selectedProduct = {
            productId,
            storeGroupId: storeGroupId || stateStoreGroupId || groupId,
            name: name || description,
            productCode: code || partNumber,
            supplierBrandId: brandId || null,
            supplierId,
            supplierName: businessSupplierName,
            supplierOriginalCode,
            supplierPartNumber,
            purchasePrice: purchasePrice || stockPrice,
            price: salePrice || sellingPrice || 0,
            count: Math.max(calculatedAvg || 0, 1),
            store: store || (supplierId === 0 ? [available, 0, 0, 0] : undefined),
            cellAddress,
            warehouseId,
            defaultWarehouseId,
            productUnitId,
            oeCode: this.props.existingOECode ? this.props.existingOECode : this.state.originalCode,
            uktz: tradeCode,
            cellAddresses,
            source
        };
        onSelect(selectedProduct);
        this.handleCancel(false);
    };

    handleCancel = (closeParent = true) => {
        this.setState({
            dataSource: [],
            page: 1,
            total: 0,
            supplierId: undefined,
            brandId: undefined,
            storeGroupId: undefined,
            code: undefined,
            mode: 0,
            filterCode: undefined,
            filterBrand: undefined,
            loading: false,
            selectedRowKeys: [],
            selectedRows: []
        });
        this.props.hideModal();
        if (closeParent) {
            this.props.closeParentModal();
        }
    };

    getColumnSearchProps = dataIndex => {
        return {
            filterDropdown: ({ confirm, clearFilters }) => (
                <div style={{ padding: 8 }}>
                    {dataIndex !== 'filterBrand' ? (
                        <Input
                            ref={node => {
                                this.searchInput = node;
                            }}
                            onChange={e => {
                                this.setState({
                                    [dataIndex]: e.target.value
                                });
                            }}
                            onPressEnter={() => this.handleSearch(confirm, dataIndex)}
                            placeholder={this.props.intl.formatMessage({
                                id: 'search'
                            })}
                            style={{ marginBottom: 8, display: 'block', width: 180 }}
                            value={this.state[dataIndex]}
                        />
                    ) : (
                        <Select
                            allowClear
                            onChange={value => {
                                this.setState({
                                    filterBrand: value
                                });
                            }}
                            optionFilterProp='children'
                            // mode='multiple'
                            placeholder={this.props.intl.formatMessage({
                                id: 'search'
                            })}
                            showSearch
                            style={{ marginBottom: 8, display: 'block', width: 180 }}
                            value={this.state.filterBrand}
                        >
                            {this.state.listBrands.map(({ brandId, brandName }) => (
                                <Option key={brandId} value={brandId}>
                                    {brandName}
                                </Option>
                            ))}
                        </Select>
                    )}
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-around'
                        }}
                    >
                        <Button
                            icon={<SearchOutlined style={{ marginRight: 4 }} />}
                            onClick={() => this.handleSearch(confirm, dataIndex)}
                            size='small'
                            type='primary'
                        >
                            <FormattedMessage id='search' />
                        </Button>
                        <Button onClick={() => this.handleReset(confirm, clearFilters, dataIndex)} size='small'>
                            <FormattedMessage id='reset' />
                        </Button>
                    </div>
                </div>
            ),
            filterIcon: () => (
                <FilterFilled
                    style={{
                        fontSize: 14,
                        color: this.state[dataIndex] ? 'var(--primary)' : undefined
                    }}
                />
            ),
            onFilterDropdownVisibleChange: visible => {
                if (visible) {
                    setTimeout(() => this.searchInput.select(), 100);
                }
            }
        };
    };

    handleSearch = async confirm => {
        confirm();
        await this.setState({ page: 1 });
        this.fetchData();
    };

    handleReset = async (confirm, clearFilters, dataIndex) => {
        confirm();
        clearFilters();
        await this.setState({ [dataIndex]: undefined, page: 1 });
        this.fetchData();
    };

    fetchData = () => {
        const { type } = this.state;
        switch (type) {
            case CROSSES:
                this.fetchFinalCrosses();
                break;
            case CODES:
                this.fetchCodes();
                break;
            case MODIFICATION:
                this.fetchByModificationId();
                break;
            case STORAGE:
                this.fetchStorage();
                break;
            case OILS:
                this.fetchOils();
                break;
            default:
                this.fetchStorage();
                break;
        }
    };

    fetchByModificationId = async () => {
        await this.setState({ loading: true, dataSource: [], type: MODIFICATION });
        try {
            const { modificationId, storeGroupId: propsStoreGroupId, selectedVehicle } = this.props;
            const { storeGroupId, pageSize, page, isAvailable, filterCode, filterBrand, filterName } = this.state;
            const bodyId = _.get(selectedVehicle, 'bodyId');
            const {
                result,
                stats,
                brandsList: listBrands
            } = await fetchTecdocAPI(
                'GET',
                '/tecdoc/products/parts',
                {
                    modificationId,
                    bodyId,
                    storeGroupId: storeGroupId || propsStoreGroupId || 1000000,
                    pageSize,
                    page,
                    isAvailable,
                    filterCode,
                    filterBrand,
                    filterName
                },
                null,
                { handleErrorInternally: true }
            );

            let productPriceIndex = 0; // Use to retrieve correct selling price
            result.forEach(product => {
                product.productPriceIndex = productPriceIndex++;
            });

            // Assign selling prices
            const sellingPrices = await fetchAPI(
                'POST',
                '/store_products/selling_prices',
                null,
                {
                    clientId: this.props.clientId || null,
                    products: result.map(product => ({
                        index: product.productPriceIndex,
                        productId: product.storeProductId || null,
                        code: product.code,
                        storeGroupId: product.storeGroupId || null,
                        businessSupplierId: product.businessSupplierId || null,
                        purchasePrice: product.purchasePrice || null
                    }))
                },
                { handleErrorInternally: true }
            );

            result.forEach(product => {
                product.sellingPrice = sellingPrices[product.productPriceIndex].sellingPrice;
            });

            const dataSource = formatDetailArray(result);
            this.setState({
                dataSource,
                total: stats.count,
                listBrands,
                filterName: undefined,
                loading: false
            });
        } catch (e) {
            this.setState({ loading: false });
        }
    };

    saveSearchHistory = async payload => {
        try {
            await fetchAPI(
                'POST',
                '/search_history/products',
                undefined,
                {
                    code: payload.code || undefined,
                    brandId: payload.brandId || undefined,
                    name: payload.name || undefined,
                    oeCode: payload.oeCode || payload.code,
                    businessSupplierId: payload.businessSupplierId || undefined
                },
                { handleErrorInternally: true }
            );
        } catch (err) {
            notification.error({ message: this.props.intl.formatMessage({ id: 'error' }) });
        }
    };

    fetchStorage = async filter => {
        await this.setState({ loading: true, dataSource: [], type: STORAGE });
        try {
            const { code, page, pageSize, isAvailable, filterCode, filterBrand, lastIncome } = this.state;
            const {
                list,
                listBrands,
                stats: { count }
            } = await fetchAPI(
                'GET',
                'store_products',
                {
                    page,
                    pageSize: pageSize - 1,
                    query: code,
                    onlyAvailable: isAvailable,
                    lastIncome,
                    filterCode,
                    filterBrand,
                    clientId: this.props.clientId,
                    ...(filter || {})
                    // filterWarehouse: this.getDetailWarehouses()
                },
                null,
                {
                    handleErrorInternally: true
                }
            );
            const dataSource = formatDetailArray(list);
            this.setState({
                dataSource: [{ emptyRow: true }, ...dataSource],
                total: count,
                loading: false,
                mode: 0,
                listBrands
            });
        } catch (e) {
            this.setState({ loading: false });
        }
    };

    fetchBrands = async () => {
        const { code, comparation } = this.state;
        if (code.length < 3) {
            return;
        }

        this.setState({
            loading: true,
            oeCode: undefined,
            storeGroupId: undefined,
            dataSource: []
        });

        const brands = await fetchTecdocAPI(
            'GET',
            '/products/search/v2',
            {
                code,
                include: this.getIncludesSources(),
                sortField: 'BRAND',
                comparation
            },
            null,
            { handleErrorInternally: true }
        );

        await this.setState({
            page: 1,
            total: 0,
            visibleBrandSelectModal: brands.length > 1,
            brands,
            loading: false,
            brandId: brands.length === 1 ? brands[0].brandId : undefined,
            filterCode: undefined,
            filterBrand: undefined
        });
        if (brands.length <= 1) {
            if (comparation === 'EQUAL') {
                this.fetchFinalCrosses();
            } else {
                this.fetchCodes();
            }
        }
        if (brands.length === 0) {
            this.noResultsNotification();
        }
    };

    fetchCodes = async () => {
        try {
            this.setState({ loading: true, dataSource: [] });
            const { code, brandId, comparation, page, pageSize, isAvailable, filterCode, filterBrand } = this.state;
            const { list, count: total } = await fetchTecdocAPI(
                'GET',
                '/products/search/v2/m2',
                {
                    code,
                    include: this.getIncludesSources(),
                    brandId,
                    comparation,
                    page,
                    pageSize: pageSize - 1,
                    isAvailable,
                    filterCode,
                    filterBrand,
                    filterSupplier: this.getDetailSuppliers(),
                    clientId: this.props.clientId
                    // filterWarehouse: this.getDetailWarehouses()
                },
                null,
                { handleErrorInternally: true }
            );

            let productPriceIndex = 0; // Use to retrieve correct selling price
            list.forEach(product => {
                product.productPriceIndex = productPriceIndex++;
            });

            const sellingPrices = await fetchAPI(
                'POST',
                '/store_products/selling_prices',
                null,
                {
                    clientId: this.props.clientId || null,
                    products: list.map(product => ({
                        index: product.productPriceIndex,
                        productId: product.storeProductId || null,
                        code: product.code,
                        storeGroupId: product.storeGroupId || null,
                        businessSupplierId: product.businessSupplierId || null,
                        purchasePrice: product.purchasePrice || null
                    }))
                },
                { handleErrorInternally: true }
            );

            list.forEach(product => {
                product.sellingPrice = sellingPrices[product.productPriceIndex].sellingPrice;
            });

            const dataSource = formatDetailArray(list);

            await this.setState({
                dataSource: [{ emptyRow: true }, ...dataSource],
                mode: 2,
                type: CODES,
                loading: false,
                total
            });

            if (list.length === 1) {
                await this.setState({
                    code: list[0].code
                });
                this.fetchFinalCrosses();
            }
        } catch (e) {
            this.setState({ loading: false });
        }
    };

    fetchFinalCrosses = async () => {
        try {
            this.setState({ loading: true, dataSource: [] });

            const { code, brandId, page, pageSize, isAvailable, filterCode, filterBrand } = this.state;

            if (!code) {
                this.setState({ loading: false });

                return;
            }

            const {
                listParts,
                list: listCrosses,
                stats: { count: total },
                listBrands
            } = await fetchTecdocAPI(
                'GET',
                '/tecdoc/crosses',
                {
                    code,
                    brandId: brandId === 8000 ? undefined : brandId,
                    page,
                    pageSize: pageSize - 2,
                    isAvailable,
                    filterCode,
                    filterBrand,
                    include: this.getIncludesSources(),
                    filterSupplier: this.getDetailSuppliers(),
                    clientId: this.props.clientId
                    // filterWarehouse: this.getDetailWarehouses()
                },
                null,
                { handleErrorInternally: true }
            );

            const updatedListParts = [listParts].map(part => {
                if (part.brandName === 'Not Defined') {
                    part.brandName = 'OES';
                }

                return part;
            });

            let productPriceIndex = 0; // Use to retrieve correct selling price
            updatedListParts.forEach(product => {
                product.productPriceIndex = productPriceIndex++;
            });

            listCrosses.forEach(product => {
                product.productPriceIndex = productPriceIndex++;
            });

            // Assign selling prices
            const sellingPrices = await fetchAPI(
                'POST',
                '/store_products/selling_prices',
                null,
                {
                    clientId: this.props.clientId || null,
                    products: [...updatedListParts, ...listCrosses].map(product => ({
                        index: product.productPriceIndex,
                        productId: product.storeProductId || null,
                        code: product.code,
                        storeGroupId: product.storeGroupId || null,
                        businessSupplierId: product.businessSupplierId || null,
                        purchasePrice: product.purchasePrice || null
                    }))
                },
                { handleErrorInternally: true }
            );

            updatedListParts.forEach(product => {
                product.sellingPrice = sellingPrices[product.productPriceIndex].sellingPrice;
            });

            listCrosses.forEach(product => {
                product.sellingPrice = sellingPrices[product.productPriceIndex].sellingPrice;
            });

            const listP = formatDetailArray(updatedListParts);
            const listC = formatDetailArray(listCrosses);

            await this.setState({
                dataSource: [...listP, { emptyRow: true }, ...listC],
                mode: 3,
                type: CROSSES,
                loading: false,
                listBrands,
                total
            });
            this.forceUpdate();
        } catch (e) {
            this.setState({ loading: false });
        }
    };

    structurizeBrands = (arr, brands, source) => {
        const result = [...arr];
        brands.forEach(elem => {
            const index = result.findIndex(({ brandId }) => brandId === elem.brandId);
            if (index >= 0) {
                if (elem.name) {
                    result[index].name += `, ${elem.name}`;
                }
                result[index].count = Number(result[index].count) + Number(elem.count);

                if (!result[index].source.includes(source)) {
                    result[index].source.push(source);
                }
            } else {
                result.push({
                    ...elem,
                    count: Number(elem.count),
                    source: [source]
                });
            }
        });

        return result;
    };

    getIncludesSources = () => {
        const sources = localStorage.getItem('_my.carbook.detail_sources');
        if (sources) {
            const result = JSON.parse(sources);
            if (result.length) {
                return result;
            }
        }

        return undefined;
        // return ['BUSINESS_PRODUCTS', 'TECDOC', 'PRICELIST', 'BUSINESS_CROSSES'];
    };

    getDetailSuppliers = () => {
        const suppliers = localStorage.getItem('_my.carbook.detail_suppliers');
        if (suppliers) {
            const result = JSON.parse(suppliers);
            if (result.length) {
                return result;
            }
        }

        return undefined;
    };

    getDetailWarehouses = () => {
        const warehouses = localStorage.getItem('_my.carbook.detail_warehouses');
        if (warehouses) {
            const result = JSON.parse(warehouses);
            if (result.length) {
                return result;
            }
        }

        return undefined;
    };

    noResultsNotification = () => {
        notification.error({
            message: this.props.intl.formatMessage({ id: 'not_found' })
        });
    };

    addInstantDetails = async () => {
        const { detailCatalogueFormDocs } = this.props;
        const { selectedRows } = this.state;
        this.setState({
            loading: true
        });
        if (!detailCatalogueFormDocs) {
            const data = {
                insertMode: true,
                details: []
            };

            selectedRows.map(element => {
                // const productCode = element.detailCode
                //     .replace(/[^A-Za-z0-9\u0400-\u04FF]/gm, '')
                //     .toUpperCase();

                const salePrice =
                    element.sellingPrice ||
                    _.get(element, 'price.purchasePrice', 0) *
                        (_.get(element, 'price.markup', 1.4) ? _.get(element, 'price.markup', 1.4) : 1.4) ||
                    1;

                if (!element.productId) {
                    data.details.push({
                        storeGroupId: element.groupId,
                        partUnitId: element.partUnitId || element.productUnitId,
                        name: element.name,
                        productCode: element.code,
                        supplierId: element.businessSupplierId || 0,
                        supplierBrandId: element.supplierBrandId || element.brandId || null,
                        supplierOriginalCode: element.supplierOriginalCode,
                        supplierProductNumber: element.supplierProductNumber,
                        supplierPartNumber: element.supplierPartNumber,
                        purchasePrice: (element.purchasePrice * 100) / 100 || 0,
                        count: 1,
                        price: element.sellingPrice ? (element.sellingPrice * 100) / 100 : salePrice,
                        oeCode: this.state.originalCode || undefined,
                        responsibleId: this.props.defaultResponsibleId || undefined,
                        comment: element.comment || {
                            comment: undefined,
                            positions: []
                        }
                    });
                } else {
                    data.details.push({
                        storeGroupId: element.groupId,
                        partUnitId: element.partUnitId || element.productUnitId,
                        name: element.name,
                        productId: element.productId,
                        productCode: element.code,
                        supplierBrandId: element.supplierBrandId || element.brandId,
                        purchasePrice: (element.purchasePrice * 100) / 100 || 0,
                        count: 1,
                        price: element.sellingPrice ? (element.sellingPrice * 100) / 100 : salePrice,
                        supplierId: element.businessSupplierId || 0,
                        cellAddress: element.cellAddress,
                        responsibleId: this.props.defaultResponsibleId || undefined,
                        oeCode: this.state.originalCode || undefined,
                        comment: element.comment || {
                            comment: undefined,
                            positions: []
                        }
                    });
                }
            });

            await fetchAPI('PUT', `orders/${this.props.orderId}`, null, data, {
                handleErrorInternally: true
            });
        } else {
            const docProducts = [];

            selectedRows.map(element => {
                // const productCode = element.detailCode
                //     .replace(/[^A-Za-z0-9\u0400-\u04FF]/gm, '')
                //     .toUpperCase();

                const salePrice =
                    element.sellingPrice ||
                    _.get(element, 'price.purchasePrice', 0) *
                        (_.get(element, 'price.markup', 1.4) ? _.get(element, 'price.markup', 1.4) : 1.4) ||
                    1;

                docProducts.push({
                    productId: element.productId || element.id,
                    brandId: element.brandId,
                    quantity: 1,
                    stockPrice: element.stockPrice || 0,
                    sellingPrice: salePrice,
                    docProductUnitId: element.productUnitId || element.partUnitId
                });
            });
            await this.props.addDocProduct(...docProducts, true);
        }

        if (detailCatalogueFormDocs) {
            this.props.handleCancel();
        } else {
            this.handleCancel();
        }
        this.setState({
            loading: false
        });

        await this.props.updateDataSource();
    };

    // componentDidMount() {
    //     this.fetchData();
    // }

    async componentDidUpdate(prevProps) {
        const { visible, type, code, brandId } = this.props;
        if (!prevProps.visible && visible) {
            if (code) {
                await this.setState({
                    code,
                    brandId
                });
                if (code && brandId) {
                    this.fetchFinalCrosses();
                } else {
                    this.fetchBrands();
                }
            } else {
                await this.setState({
                    type: type || STORAGE
                });
                this.fetchData();
            }

            setTimeout(() => this.input.current.focus(), 500);
        }
    }

    handleSearchDetailOpen = () => {
        this.setState({ searchDetailOpen: true });
    };

    handleSearchDetailClose = () => {
        this.setState({ searchDetailOpen: false });
    };

    handleSearchMode = e => {
        this.setState({ selectedSearchMode: e.key });
    };

    render() {
        const {
            visible,
            setModal,
            saveModal,
            treeData,
            suppliers,
            showOilModal,
            orderId,
            warehouses,
            modificationId,
            updateDataSource,
            vin,
            user,
            isMobile,
            intl: { formatMessage },
            existingOECode
        } = this.props;
        const {
            dataSource,
            reserveModalVisible,
            page,
            pageSize,
            total,
            code,
            storeGroupId,
            loading,
            visibleBrandSelectModal,
            comparation,
            brands,
            type,
            visibleSourceSelectModal,
            photoModalRow,
            mode,
            isAvailable,
            visibleSupplierSelectModal,
            visibleStoreGroupSelectModal,
            warehousesModalSelectedRow,
            visibleStorageSelectModal,
            filterName,
            selectedRowKeys,
            selectedRows,
            lastIncome,
            selectedSearchMode,
            oeCode,
            searchDetailOpen,
            mediaFilesRow
        } = this.state;

        const columns = !isMobile
            ? this.columns()
            : this.columns().filter(
                  ({ key }) => key === 'actions' || key === 'code' || key === 'brand' || key === 'salePrice'
              );

        const include = this.getIncludesSources();
        const supplier = this.getDetailSuppliers();
        const warehouse = this.getDetailWarehouses();

        const searchBlock = (
            <div className='headerModal'>
                <div className='headerModal_col'>
                    <Space.Compact className='styleInpButton'>
                        <Input
                            ref={this.input}
                            allowClear
                            data-qa='table_detail_code_input_order_detail_modal'
                            onChange={async event => {
                                const { value: code } = event.target;
                                await this.setState({ code });
                                if (type === STORAGE && !mode) {
                                    this.handleStorageSearch();
                                }
                                if (!code) {
                                    await this.setState({
                                        oeCode: undefined,
                                        storeGroupId: undefined
                                    });
                                    this.fetchStorage();
                                }
                            }}
                            onPressEnter={this.fetchBrands}
                            placeholder={formatMessage({ id: 'order_form_table.detail_search' })}
                            value={code}
                        />
                        <Tooltip title={<FormattedMessage id='order_form_table.apply_btn' />} zIndex={2001}>
                            <Button
                                data-qa='btn_fetch_storage_oils_order_detail_modal'
                                disabled={!code}
                                icon={<SearchOutlined />}
                                onClick={this.fetchBrands}
                                type='primary'
                            />
                        </Tooltip>
                        <Tooltip title={<FormattedMessage id='filters' />} zIndex={2001}>
                            <Button
                                disabled={selectedSearchMode !== STORAGE}
                                icon={<FilterOutlined />}
                                onClick={this.handleSearchDetailOpen}
                            />
                        </Tooltip>
                    </Space.Compact>
                </div>
                <div className='btnInAction' style={{ textAlign: isMobile && 'center' }}>
                    <Tooltip title={<FormattedMessage id='add_order_form.vin' />} zIndex={2001}>
                        <Button
                            data-qa='btn_add_form_vin_order_detail_modal'
                            disabled={!vin || !isGrantAccessed(user, grants.OPERATIONS_ORDER_DOCUMENT_PARTS_VIN)}
                            icon={<VinIcon style={{ fontSize: 20 }} />}
                            onClick={() => {
                                saveModal();
                                setModal(MODALS.VIN_MODAL, {
                                    storeGroupId: storeGroupId || this.props.storeGroupId,
                                    setVinDetail: product => this.vinModalAction(product),
                                    setVinDetails: async codes => {
                                        const data = {
                                            insertMode: true,
                                            details: codes.map(({ oem, name, storeGroupId }) => ({
                                                productCode: oem,
                                                name,
                                                groupId: storeGroupId,
                                                oeCode: oem
                                            }))
                                        };
                                        await fetchAPI('PUT', `orders/${this.props.orderId}`, null, data, {
                                            handleErrorInternally: true
                                        });
                                        this.handleCancel();
                                        updateDataSource();
                                    },
                                    modalToLoad: MODALS.ORDER_DETAIL_MODAL
                                });
                            }}
                            style={{ marginRight: 8 }}
                        />
                    </Tooltip>
                    <Dropdown
                        onClick={this.handleSearchMode}
                        overlay={
                            <Menu>
                                <Menu.Item
                                    key='search_by_car'
                                    disabled={!modificationId}
                                    onClick={() => {
                                        if (!this.props.storeGroupId) {
                                            this.setState({
                                                visibleStoreGroupSelectModal: true
                                            });
                                        } else {
                                            this.fetchByModificationId();
                                        }
                                    }}
                                >
                                    <FormattedMessage id='order_form_table_search_by_car' />
                                </Menu.Item>
                                {isGrantAccessed(
                                    user,
                                    grants.OPERATIONS_ORDER_DOCUMENT_PARTS_STORES_AND_RESERVATIONS
                                ) && (
                                    <Menu.Item key='storage' onClick={this.fetchStorage}>
                                        <FormattedMessage id='navigation.storage' />
                                    </Menu.Item>
                                )}
                                <Menu.Item key='search_oil' disabled={!showOilModal} onClick={showOilModal}>
                                    <FormattedMessage id='order_from_table_search_oil' />
                                </Menu.Item>
                                <Menu.Item
                                    key='supplier_search'
                                    onClick={() => {
                                        Modal.confirm({
                                            title: this.props.intl.formatMessage({
                                                id: 'order_from_table_search_supplier'
                                            }),
                                            content: (
                                                <Input
                                                    onChange={e => {
                                                        this.setState({
                                                            filterName: e.target.value
                                                        });
                                                    }}
                                                    value={filterName}
                                                />
                                            ),
                                            onOk: () => {
                                                this.fetchByModificationId();
                                            },
                                            onCancel: () => {
                                                this.setState({ filterName: undefined });
                                            }
                                        });
                                    }}
                                >
                                    <FormattedMessage id='order_from_table_search_supplier' />
                                </Menu.Item>
                            </Menu>
                        }
                        overlayStyle={{
                            zIndex: 9999
                        }}
                        selectedKeys={[selectedSearchMode]}
                        trigger={['click']}
                    >
                        <Tooltip title={<FormattedMessage id='order_form_table.catalog' />} zIndex={2001}>
                            <Button
                                data-qa='btn_selection_by_product_code_order_detail_modal'
                                icon={<BookIcon style={{ fontSize: 20 }} />}
                            />
                        </Tooltip>
                    </Dropdown>
                </div>
                <div className='headerModal_col'>
                    <div className='headerModal_bottomRow'>
                        <Radio.Group
                            onChange={({ target: { value: comparation } }) => this.setState({ comparation })}
                            value={comparation}
                        >
                            <Radio style={{ display: isMobile && 'block' }} value='EQUAL'>
                                <FormattedMessage id='order_form_table.exact_match' />
                            </Radio>
                            <Radio style={{ display: isMobile && 'block' }} value='STARTS_WITH'>
                                <FormattedMessage id='order_form_table.starts_with' />
                            </Radio>
                            <Radio style={{ display: isMobile && 'block' }} value='INCLUDES'>
                                <FormattedMessage id='order_form_table.includes' />
                            </Radio>
                        </Radio.Group>

                        <div style={{ marginTop: isMobile && 14 }}>
                            <Checkbox
                                checked={isAvailable}
                                onChange={async () => {
                                    await this.setState({
                                        isAvailable: !isAvailable,
                                        page: 1
                                    });
                                    this.fetchData();
                                }}
                                style={{
                                    paddingLeft: 8
                                }}
                            >
                                <FormattedMessage id='order_form_table.in_stock' />
                            </Checkbox>
                        </div>
                        <div style={{ marginTop: isMobile && 14 }}>
                            <Checkbox
                                checked={lastIncome}
                                onChange={async () => {
                                    await this.setState({
                                        lastIncome: !lastIncome,
                                        page: 1
                                    });
                                    this.fetchData();
                                }}
                                style={{
                                    paddingLeft: 8
                                }}
                            >
                                <FormattedMessage id='order_form_table.lastIncome' />
                            </Checkbox>
                        </div>
                    </div>
                </div>
                <Space style={{ textAlign: isMobile && 'center' }}>
                    <Tooltip title={<FormattedMessage id='orders.source' />} zIndex={2001}>
                        <Button
                            icon={<FolderOpenOutlined style={{ fontSize: 18 }} />}
                            onClick={() => {
                                this.setState({ visibleSourceSelectModal: true });
                            }}
                            style={{
                                borderColor: include && 'var(--primary)'
                            }}
                        />
                    </Tooltip>
                    <Tooltip title={<FormattedMessage id='storage_document.supplier' />} zIndex={2001}>
                        <Button
                            disabled={!isAvailable}
                            icon={<DeliveryTruckIcon style={{ fontSize: 20 }} />}
                            onClick={() => {
                                this.setState({ visibleSupplierSelectModal: true });
                            }}
                            style={{
                                borderColor: isAvailable && supplier && 'var(--primary)'
                            }}
                        />
                    </Tooltip>
                    <Tooltip title={<FormattedMessage id='storage_document.warehouses' />} zIndex={2001}>
                        <Button
                            disabled
                            icon={<StockIcon style={{ fontSize: 18 }} />}
                            onClick={() => {
                                this.setState({ visibleStorageSelectModal: true });
                            }}
                            style={{
                                borderColor: warehouse && 'var(--primary)'
                            }}
                        />
                    </Tooltip>
                </Space>
            </div>
        );

        const pagination = {
            pageSize,
            total,
            hideOnSinglePage: true,
            current: page,
            onChange: async (page, pageSize) => {
                await this.setState({ page, pageSize });
                this.fetchData();
            }
        };

        const rowSelection = {
            selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({
                    selectedRowKeys,
                    selectedRows
                });
            },
            getCheckboxProps: record => ({
                disabled: !record.code,
                style: !record.code ? { display: 'none' } : {}
            })
        };

        return (
            <React.Fragment>
                <Modal
                    className='catalogueModal'
                    footer={
                        <div>
                            <Button
                                onClick={() => {
                                    this.handleCancel();
                                }}
                            >
                                <FormattedMessage id='stock_table.button.cancel' />
                            </Button>
                            <Button
                                disabled={!_.get(selectedRows, 'length')}
                                loading={loading}
                                onClick={() => this.addInstantDetails()}
                                type='primary'
                            >
                                <FormattedMessage id='ok' />
                            </Button>
                        </div>
                    }
                    maskClosable={false}
                    onCancel={this.handleCancel}
                    open={visible}
                    title={<FormattedMessage id='add_detail' />}
                    width='96%'
                >
                    <Table
                        bordered
                        className='ordDetailModalTable'
                        columns={columns}
                        dataSource={dataSource}
                        getPopupContainer={trigger => trigger.parentNode}
                        loading={loading}
                        onRow={record => ({
                            onDoubleClick: () => {
                                if (record.code) {
                                    this.handleOk(record);
                                }
                            }
                        })}
                        pagination={pagination}
                        rowClassName={row => {
                            if (row.emptyRow) return 'emptyRow';
                        }}
                        rowKey={row => `${row.code || row.partNumber}-${row.description || row.name}-${row.brandName}`}
                        rowSelection={!this.props.detailCatalogueFormDocs ? rowSelection : null}
                        size='small'
                        title={() => searchBlock}
                    />
                </Modal>
                {this.props.stockMode && (
                    <StoreProductTrackingModal
                        hideModal={() => {
                            this.setState({
                                reserveModalVisible: undefined
                            });
                        }}
                        productId={reserveModalVisible}
                        visible={Boolean(reserveModalVisible)}
                    />
                )}
                <BrandSelectModal
                    brands={brands}
                    hideModal={() => this.setState({ visibleBrandSelectModal: false })}
                    onSelect={async ({ brandId, count }) => {
                        await this.setState({ brandId });
                        if (comparation !== 'EQUAL') {
                            this.fetchCodes();
                        } else {
                            this.fetchFinalCrosses();
                        }
                    }}
                    visibleBrandSelectModal={visibleBrandSelectModal}
                />
                <SourceSelectModal
                    hideModal={async () => {
                        await this.setState({ visibleSourceSelectModal: false });
                        this.fetchData();
                    }}
                    visible={visibleSourceSelectModal}
                />
                <SupplierSelectModal
                    hideModal={async () => {
                        await this.setState({ visibleSupplierSelectModal: false });
                        this.fetchData();
                    }}
                    suppliers={suppliers}
                    visible={visibleSupplierSelectModal}
                />
                <StorageSelectModal
                    hideModal={async () => {
                        await this.setState({ visibleStorageSelectModal: false });
                        this.fetchData();
                    }}
                    visible={visibleStorageSelectModal}
                    warehouses={warehouses}
                />
                <StoreGroupSelectModal
                    hideModal={() => this.setState({ visibleStoreGroupSelectModal: false })}
                    onSelect={async storeGroupId => {
                        // await setStoreGroupId(storeGroupId);
                        await this.setState({ storeGroupId });
                        this.fetchByModificationId();
                    }}
                    treeData={treeData}
                    visible={visibleStoreGroupSelectModal}
                />
                <PhotoModal
                    data={photoModalRow}
                    hideModal={() => this.setState({ photoModalRow: false })}
                    visible={Boolean(photoModalRow)}
                />
                <DetailWarehousesCountModal
                    count={_.get(warehousesModalSelectedRow, 'count', 1)}
                    hideButton
                    hideModal={() => {
                        this.setState({
                            warehousesModalSelectedRow: undefined
                        });
                    }}
                    onSelect={(address, warehouseId, whName, purchasePrice) => {
                        this.handleOk({
                            ...warehousesModalSelectedRow,
                            businessSupplierId: 0,
                            cellAddress: address,
                            warehouseId,
                            productId: _.get(warehousesModalSelectedRow, 'productId'),
                            purchasePrice: purchasePrice || undefined
                        });
                    }}
                    orderId={orderId}
                    productId={_.get(warehousesModalSelectedRow, 'productId')}
                    productUnit={_.get(warehousesModalSelectedRow, 'unit')}
                    visible={Boolean(warehousesModalSelectedRow)}
                />

                <SearchDetailModal
                    onClose={this.handleSearchDetailClose}
                    onOk={filter => this.fetchStorage(filter)}
                    open={searchDetailOpen}
                />
                <PreviewMediaFilesModal
                    hideModal={() =>
                        this.setState({
                            mediaFilesRow: false
                        })
                    }
                    open={Boolean(mediaFilesRow)}
                    prdId={mediaFilesRow ? mediaFilesRow.id || mediaFilesRow.storeProductId : mediaFilesRow}
                />
            </React.Fragment>
        );
    }
}
export default DetailCatalogueModal;
